import { ChangeDetectorRef, Component } from '@angular/core';
import { AuthService } from '../services/auth.service';   
import { Router } from '@angular/router';
import { JwtService } from '../services/jwt.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})
export class AuthenticationComponent {
  userDetails:any={};
  public loggedInStatus:any = false;
  constructor(private router: Router  
    , private authService:AuthService
    , public jwtService:JwtService
    , private ref: ChangeDetectorRef) {

      jwtService.eventEmitterUser.subscribe(resData => {
        this.userDetails = resData.data
        if( this.userDetails.id>0){
          this.loggedInStatus = true
        }else{
          this.loggedInStatus = false
        }
        ref.detectChanges()
      })  
  }

  ngOnInit() {
    this.jwtService.getUserDetail()
  }

  ngAfterViewInit(){
    if(this.loggedInStatus){
      this.router.navigate(['/notes/list'])
    }else{
      this.googleBtnIni();
    }
 
  }
   
    
  googleBtnIni(){
    // @ts-ignore
    //if(google){
     // @ts-ignore
      google.accounts.id.initialize({
        client_id: environment.google.client_id,
        callback: this.handleCredentialResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true,
        //cookiepolicy: 'single_host_origin',
    
      });
     
        // @ts-ignore
      google.accounts.id.renderButton(
      // @ts-ignore
          document.getElementById("google-button-auth"),
            { theme: "outline",text:"Continue with google", size: "big", width: "100%" }
        );
        
      // @ts-ignore
      /*
      google.accounts.id.prompt((notification: PromptMomentNotification) => {

        console.log(notification)
      });
      */
    //}
  }
  async handleCredentialResponse(response: any) {
    // Here will be your response from Google.
    // console.log(response);
    if(response.credential){
      this.authService.loginWithGoogle(response);
    }
  }
}
