import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { AngularFireModule } from '@angular/fire';
//import { AngularFireAuthModule } from '@angular/fire/auth';



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TempComponent } from './temp/temp.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';


/*
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
*/

import { NotesModule } from './notes/notes.module';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { WithoutLoginHomeComponent } from './without-login-home/without-login-home.component';
import { WithoutLoginHeaderComponent } from './without-login-header/without-login-header.component';
import { WithoutLoginComponent } from './layouts/without-login/without-login.component';
import { WithLoginComponent } from './layouts/with-login/with-login.component';

//import { TempComponent } from './temp/temp.component';
//import { DashboardComponent } from './dashboard/dashboard.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { MaterialModule } from './material.module'
import { SharedModule } from './shared/shared.module'

import { NgxSummernoteModule } from 'ngx-summernote';

import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
 
import { ApiService } from './services/api.service';
import { SearchService } from './services/search.service';
//import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { WithoutLoginFooterComponent } from './without-login-footer/without-login-footer.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { InsightsComponent } from './insights/insights.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermConditionsComponent } from './term-conditions/term-conditions.component';

//import {APP_BASE_HREF} from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    WithoutLoginComponent,
    WithLoginComponent,
    WithoutLoginHeaderComponent,
    HeaderComponent,
    FooterComponent,
    WithoutLoginHomeComponent,
    TempComponent,
    DashboardComponent,
    HomeComponent,
    SidenavComponent,
    WithoutLoginFooterComponent,
    AboutUsComponent,
    ContactUsComponent,
    InsightsComponent,
    AuthenticationComponent,
    PrivacyPolicyComponent,
    TermConditionsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    SharedModule,
   // FormsModule,
 
    NotesModule,
    NgxSummernoteModule,
    HttpClientModule,
    NgbModule,
    //provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    //AngularFireModule.initializeApp(firebaseConfig),
    //AngularFireAuthModule
    NgxUiLoaderModule
    
  ],
  providers: [ApiService,SearchService], //{provide: APP_BASE_HREF, useValue: '/c2wow/'}],
  bootstrap: [AppComponent]
})
export class AppModule { }
