<div class="main-layout inner_page">
<!-- header -->
<div class="header">
    <div class="container">
       <div class="row d_flex">
          <div class=" col-md-2 col-sm-3 col logo_section">
             <div class="full">
                <div class="center-desk">
                   <div class="logo">
                      <a routerLink="/"><img src="./assets/img/avtr_ai.png" alt="#" /></a>
                   </div>
                </div>
             </div>
          </div>
          <div class="col-md-8 col-sm-12">
             <nav class="navigation navbar navbar-expand-md navbar-dark ">
                <button (click)="menuOpenStatus=!menuOpenStatus" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample04" aria-controls="navbarsExample04" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" [ngClass]="{'show':menuOpenStatus}" id="navbarsExample04">
                   <ul class="navbar-nav mr-auto">
                      <li (click)="menuOpenStatus=false" class="nav-item active p-2">
                         <a class="nav-link" routerLink="/home">Home</a>
                      </li>
                      <li (click)="menuOpenStatus=false" class="nav-item  p-2">
                        <a class="nav-link" routerLink="/auth">NotesWiz</a>
                     </li>     
                      <li (click)="menuOpenStatus=false" class="nav-item  p-2">
                         <a class="nav-link" routerLink="/insights">Insights</a>
                      </li>                     
                      <li (click)="menuOpenStatus=false" class="nav-item  p-2">
                         <a class="nav-link" routerLink="/contact-us">Contact Us</a>
                      </li>
                      <li (click)="menuOpenStatus=false" *ngIf="!loggedInStatus" class="nav-item  p-2 d-block d-md-none ">
                        <a class="nav-link" routerLink="/auth">Sign In</a>
                     </li>
                     <li *ngIf="loggedInStatus" class="nav-item  p-2 d-block d-md-none">
                        <a class="nav-link" (click)="logOut()">Sign Out</a>
                     </li>
                      
                   </ul>
                </div>
             </nav>
          </div>
          <div class="col-md-2  d-none d-md-block">
             <ul class="email text_align_right">
                <li>
                   
                  <button  [matMenuTriggerFor]="menu" mat-fab color="primary"
                     *ngIf="loggedInStatus" 
                      class="user mt-2 d-flex align-items-center">
                      <img [src]="userDetails.profile_pic"  [alt]="userDetails.name.substring(0,2)" class="user-image " />
                    </button>
                    <mat-menu #menu="matMenu">
                      <button (click)="logOut()" mat-menu-item>
                        <mat-icon>exit_to_app</mat-icon>
                        Logout
                      </button>
                    </mat-menu>
                   
                     <a *ngIf="!loggedInStatus" routerLink="/auth">Sign In
                     </a>
                </li>
             </ul>
          </div>
       </div>
    </div>
 </div>
 <!-- end header inner -->


</div>