import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';

@Injectable({
  providedIn: 'root'
})
export class NotesService {

  constructor(private apiService:ApiService, private jwtService:JwtService) { }

  addNewNote(params: any) {
    return this.apiService.post('/note/note/addeditnote',params)
  }

  
  noteChangeStatus(params: any) {
    return this.apiService.post('/note/note/changestatus',params)
  }

  getAllNote() {
    return this.apiService.get('/note/notes/list')
  }

  getNote(note_id: any) {
    return this.apiService.get('/note/note/'+note_id)
  }

  getNotePreview(note_id: any) {
    return this.apiService.get('/note/preview/'+note_id)
  }
 
  addNewPage(params: any) {
    return this.apiService.post('/note/notepage/addnotepage',params)
  }


  pageAddUpdate(params: any) {
    return this.apiService.post('/note/notepage/editnotepage',params)
  }

  deleteNotePage(notePageId:number){
    return this.apiService.delete('/note/notepage/delete/'+notePageId)
  }

  aiHelpSerach(search: string) {
    let params:any = {'search':search};
    return this.apiService.get('/home/search',params)
  }
}
