<mat-nav-list>
    <div class="logo py-4">
      <a routerLink="/"><img src="./assets/img/avtr_ai.png" alt="c2wow" /></a>
    </div>
    <mat-divider></mat-divider>
    <!-- h2 matSubheader class="mt-2">APPLICATIONS</h2 -->
  
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/">
      <mat-icon color="primary">home</mat-icon> Home
    </a>
    <a mat-list-item routerLinkActive="list-item-active" (click)="routerLinkNav()" routerLink="/notes/list">
      <mat-icon color="primary">library_books</mat-icon> Notes
    </a>
    <!-- a mat-list-item routerLinkActive="list-item-active" routerLink="/notes/note/3/0">
      <mat-icon color="primary">dashboard</mat-icon> Edit Notes
    </a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/temp">
      <mat-icon color="primary">dashboard</mat-icon> Temp
    </a -->
  </mat-nav-list>
  <app-footer></app-footer>