import { Component } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {
  constructor(private router: Router, public globalService:GlobalService) {
    }
  routerLinkNav(){
    this.globalService.emitterSidebarMainMenu.next({'closeSidebar':true})
  }
}
