import { ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { JwtService } from '../services/jwt.service';

@Component({
  selector: 'app-without-login-header',
  templateUrl: './without-login-header.component.html',
  styleUrls: ['./without-login-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WithoutLoginHeaderComponent {
  menuOpenStatus:any=false

  userDetails:any={};
  public loggedInStatus:any = false;

  constructor(private router: Router
    , private authService:AuthService
    , public jwtService:JwtService, 
    private ref: ChangeDetectorRef) {

    //this.ngxUiLoaderService.start('mainSearchLoader');
    jwtService.eventEmitterUser.subscribe(resData => {
      this.userDetails = resData.data
      if( this.userDetails.id>0){
        this.loggedInStatus = true
      }else{
        this.loggedInStatus = false
      }
      ref.detectChanges()
    })
  }

  ngOnInit() {
  this.jwtService.getUserDetail()
  }
  logOut(){
    this.menuOpenStatus = false
    this.jwtService.logout();
    this.router.navigate(['/home'])
  }
}
