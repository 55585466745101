import { Component, ViewChild } from '@angular/core';
import { ContactUs } from '../models/requests/contact-us/contact-us';
import { GlobalService } from '../services/global.service';
 

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  @ViewChild('contactform', {static: true}) public contactform: any;
  submit_try = false
  formData = {name:'',phone:'',email:'',message:''}

  constructor(private globalService:GlobalService ){
    }
  submitForm(){
    
    this.submit_try = true
    if (this.contactform.nativeElement.checkValidity()) {
      this.globalService.showHideLoader('Loader_ContactsUs')
      this.globalService.contactUs(this.formData).subscribe(res => {
        this.globalService.showHideLoader('Loader_ContactsUs',false)
        if(res.status){
          this.formData = {name:'',phone:'',email:'',message:''}
          this.globalService.showError('Submitted Successfully.')
        } else {
          this.globalService.showError('Failed to submitted.')
        }
      },err=>{
        this.globalService.showHideLoader('Loader_ContactsUs',false)
        this.globalService.showError('Failed to submitted.')
      })
    }
  }
}
