<div class="container-fluid page">
    <div class="d-flex page__box p-3 mt-2">
      <mat-toolbar>
        <span>My Application</span>
      </mat-toolbar>
    </div>
    <div class="page__content shadow p-3 position-relative">
      <div class="page__content-data position-absolute text-center">
        Dashboard
      </div>
    </div>
  </div>