<div class="container">
  <h2 class="py-4">Cars</h2>

  <div class="form-group mb-4">
    <mat-form-field class="example-form-field">
      <input matInput class="form-control" type="text" [(ngModel)]="searchText" placeholder="Search">
    </mat-form-field>

    <div class="example-button-row">
      Notes
      <div class="right">
      <button mat-button>Basic</button>
      <button mat-button color="primary">Primary</button>
    </div>
    </div>
    
  </div>

  <table class="table" *ngIf="(cars | filter: searchText).length > 0; else noResults">
    <colgroup>
      <col width="5%">
      <col width="*">
      <col width="35%">
      <col width="15%">
    </colgroup>
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Brand</th>
        <th scope="col">Model</th>
        <th scope="col">Year</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let car of cars | filter: searchText; let i = index">
        <th scope="row">{{i + 1}}</th>
        <td>{{car.brand}}</td>
        <td>{{car.model}}</td>
        <td>{{car.year}}</td>
      </tr>
    </tbody>
  </table>

  <ng-template #noResults>
    <p>No results found for "{{searchText}}".</p>
  </ng-template>
</div>