<div class=" pt-2">
    <div class="container">
       <div class="row pt-md-4">
       
        <div class="c2wow-login-form border rounded col-12 col-md-6 p-md-5 offset-md-3">

                <div class="c2wow-form-heading text_align_center form-outline mb-4">
                  <h2>Log in to C2wow </h2>
                </div>
                <!-- Email input -->
                <div class="form-outline mb-4">
                  <!-- <input type="email" id="form2Example1" placeholder="Email" class="c2wow-form-input form-control" /> -->
                  
                </div>
              
                
              
                <!-- Submit button -->
                <!-- <button type="button" class="c2wow-form-button btn btn-success btn-block mb-4">Continue with email</button>
              <p class="c2wow-line-separator"><span>or</span></p> -->
              
                <!-- <button type="button" class="c2wow-form-button btn btn-primary btn-block mb-4">Continue with google</button> -->
               
                <div class="c2wow-form-button btn btn-primary btn-block mb-4" id="google-button-auth"></div>

                
             
            
        </div>
         
        </div>
        </div>
        </div>
 

 
 