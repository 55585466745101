import { NgModule,ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEditNoteComponent } from './dialogs/add-edit-note/add-edit-note.component'; 
import { FilterPipe } from './pipes/filter.pipe';
import { MaterialModule } from '../material.module';
import { Nl2brPipe } from './pipes/nl2br.pipe';
import { PreviewDocComponent } from './component/preview-doc/preview-doc.component'
@NgModule({
  declarations: [
    AddEditNoteComponent,
    FilterPipe,
    Nl2brPipe,
    PreviewDocComponent],
  imports: [
    CommonModule,
    MaterialModule,
  ],
  providers:[],
  exports:[FilterPipe,Nl2brPipe,PreviewDocComponent]
})

export class SharedModule {}
/*
export class SharedModule { 
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [ ]
    };
  }
}
*/
