<div class="header-main container-fluid sticky-top">
  <div class="row ">
   <div class="col-3 d-inline-flex p-md-1">
     <button *ngIf="loggedInStatus" mat-icon-button (click)="openSidebar()" class="m-1 mx-2" aria-label="Example icon-button with menu icon">
       <mat-icon>menu</mat-icon>
     </button>
   
    <div class="logo1 py-2 d-none d-sm-block">
      <a routerLink="/home"><img src="/assets/img/avtr_ai.png" alt="c2wow" /></a>
    </div>
   </div>

   <div class="col-6 col-sm-6 pl-3 pl-sm-4 py-sm-3 py-2">    
      <!-- <div class="form"> 
        <input maxlength="50" type="text" class="form-control form-input" placeholder="" (keyup.enter)="searchInit()" [(ngModel)]="searchText">
        <span class="left-pan">
          <button (click)="searchInit()" class="serach-btn" mat-icon-button color="primary" aria-label="Send" matSuffix>
            <mat-icon matPrefix>search</mat-icon>
          </button>
          </span>
      </div> -->
   </div>

   <div class="col-1  offset-sm-1 pl-4">
    <div [hidden]='loggedInStatus' class="pt-4">
      <div class="" id="google-button"></div>
    </div>
   <button  [matMenuTriggerFor]="menu" mat-fab color="primary"
      *ngIf="loggedInStatus" 
       class="user mt-2 d-flex align-items-center">
       <img [src]="userDetails.profile_pic"  [alt]="userDetails.name.substring(0,2)" class="user-image " />
     </button>
     <mat-menu #menu="matMenu">
       <button (click)="logOut()" mat-menu-item>
         <mat-icon>exit_to_app</mat-icon>
         Logout
       </button>
     </mat-menu>
   </div>
 </div>
 </div>