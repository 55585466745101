import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotesListComponent } from './notes-list/notes-list.component';
import { NotesAddEditComponent } from './notes-add-edit/notes-add-edit.component'
import { NotePreviewComponent } from './note-preview/note-preview.component';
import { LoginResolver } from '../services/resolvers/login-resolver';

const routes: Routes = [
                {
                  path: 'preview/:note_id',
                  component:NotePreviewComponent
                },
                {
                  path: 'preview/:note_id/:page_id',
                  component:NotePreviewComponent
                },
                {
                  path: 'list',
                  component:NotesListComponent,
                  resolve:{
                    isAuthUser:LoginResolver
                  }
                },
                {
                  path: 'note/:note_id',
                  component:NotesAddEditComponent,
                  resolve:{
                    isAuthUser:LoginResolver
                  }
                },
                {
                  path: 'note/:note_id/:page_id',
                  component:NotesAddEditComponent,
                  resolve:{
                    isAuthUser:LoginResolver
                  }
                }];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotesRoutingModule { }
