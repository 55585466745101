import { JsonConverter, JsonCustomConvert, JsonConvert } from "json2typescript";

@JsonConverter
export class DateConverter implements JsonCustomConvert<Date> {
    serialize(data: Date) {
        return data.getFullYear()+'-'+(data.getMonth()+1)+'-'+data.getDate();
    }
    deserialize(data: any): Date {
        if(data == null){
            return data;
        }else{
            return new Date(data)
        }
    }
}

@JsonConverter
export class NullableString implements JsonCustomConvert<String> {
    serialize(data: string): any {
        if(data == null){
            return '';
        } else {
            return data.toString()
        }
    }
    deserialize(data: any): any {
        if(data == null){
            return '';
        } else {
            return data.toString()
        }
    }
}


@JsonConverter
export class NullableInt implements JsonCustomConvert<String> {
    serialize(data: string): any {
        if(data == null){
            return 0;
        } else {
            return data.toString()
        }
    }
    deserialize(data: any): any {
        if(data == null){
            return 0;
        } else {
            return data.toString()
        }
    }
}


@JsonConverter
export class NumberConvert implements JsonCustomConvert<String> {
    serialize(data: any): any {
        if(isNaN(data)){
            return 0
        }
        return parseFloat(data)
    }
    deserialize(data: any): any {
        if(isNaN(data)){
            return 0
        }
        return parseFloat(data)
    }
}


@JsonConverter
export class NumberStringConvert implements JsonCustomConvert<String> {
    serialize(data: any): any {
        return String(data)
    }
    deserialize(data: any): any {
        return String(data)
    }
}


@JsonConverter
export class BooleanConvert implements JsonCustomConvert<String> {
    serialize(data: any): any {
        if(data == true){
            return 1;
        } else {
            return 0
        }
    }
    deserialize(data: any): any {
        if(data == 0){
            return false
        } else {
            return true
        }
    }
}