import { Component, OnInit, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-with-login',
  templateUrl: './with-login.component.html',
  styleUrls: ['./with-login.component.scss']
})
export class WithLoginComponent {
  title = 'c2wow';
  sideBarOpen = false;

  constructor(private el:ElementRef,public globalService:GlobalService,private ref: ChangeDetectorRef){
      console.log('production:- ',environment.env); // Logs false for development environment
      globalService.fetchMasterData()

      globalService.emitterSidebarMainMenu.subscribe(dataInp => {
        this.sideBarToggler(dataInp)
      })
  }
  //@HostListener('click', ['$event.target'])
  onClick(target:any){
    console.log(target)
    this.sideBarOpen = false;
  }
  sideBarToggler($event:any) {
     let runDef = true
    if($event['toggleSidebar']){
      runDef = false
      this.sideBarOpen = !this.sideBarOpen;
    }
    if($event['closeSidebar']){
      runDef = false
      this.sideBarOpen = false;
    }
    if($event['openSidebar']){
      runDef = false
      this.sideBarOpen = false;
      this.ref.detectChanges()
      this.sideBarOpen = true;
    }
    if(runDef){
      this.sideBarOpen = false;
    }
    this.ref.detectChanges()
  }
  closeMenu(){
    this.sideBarOpen = false;
  }
}

