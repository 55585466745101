import { Component } from '@angular/core';

@Component({
  selector: 'app-without-login-home',
  templateUrl: './without-login-home.component.html',
  styleUrls: ['./without-login-home.component.scss']
})
export class WithoutLoginHomeComponent {

}
