import { Injectable, EventEmitter } from '@angular/core';
//import { Configuration, OpenAIApi } from 'openai';
import { Observable } from 'rxjs';
 
 

@Injectable({
  providedIn: 'root'
})
export class AiService {
  eventEmitterHeaderSearch: EventEmitter<any> = new EventEmitter();

 
  constructor() { }

  searchReq(searchText:string) {
     
        this.eventEmitterHeaderSearch.next({resType:'createCompletion',data:'data'});
        //observer.next(data);
       
      
    //})
    /*
    const response = await openai.createCompletion({
      model: "text-davinci-003",
      prompt: "Write content about babas in INDIA",
      temperature: 0,
      max_tokens: 1000,
      top_p: 1,
      frequency_penalty: 0.2,
      presence_penalty: 0,
    });
*/

     /*
    const response = await openai.createCompletion({
      model: "text-davinci-003",
      prompt: "I want to discuss about human inteligence vs AI",
      temperature: 0.9,
      max_tokens: 1000,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0.6,
      stop: [" Human:", " AI:"],
    });
    

    console.log(response)
    */
  }


  aiImage(searchText:string){
     
      this.eventEmitterHeaderSearch.next({resType:'aiImage',data:'data'});
      
    
  }
}
