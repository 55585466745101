import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-without-login-footer',
  templateUrl: './without-login-footer.component.html',
  styleUrls: ['./without-login-footer.component.scss']
})
export class WithoutLoginFooterComponent {
  env:any = environment
}
