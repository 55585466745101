import { Component, ViewChild, AfterViewInit } from '@angular/core';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort, Sort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AddEditNoteComponent } from '../../shared/dialogs/add-edit-note/add-edit-note.component'
import {MatTableDataSource} from '@angular/material/table'
import { GlobalService } from 'src/app/services/global.service';
import { NotesService } from 'src/app/services/notes.service';
import { Router } from '@angular/router';

 
export interface PeriodicElement {
  topic: string;
  position: number;
  pages: number;
  createdOn: string;
  publishedOn: string;
  status: string;
}

 

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss']
})

export class NotesListComponent implements AfterViewInit {
  animal: any;
  displayedColumns: string[] = ['select','position', 'topic', 'pages','createdOn','publishedOn','status','actions'];
  // dataSource = ELEMENT_DATA;
  dataSource:any[] = []  
  new_note_title:string = ''
  masterData:any
  
  constructor(public dialog: MatDialog, private router: Router, private _liveAnnouncer: LiveAnnouncer,
    public globalService:GlobalService, private notesService:NotesService,) {
      this.getAllNote()
      globalService.eventEmitterMasterData.subscribe(res=>{
        this.masterData = res
      })

      globalService.getMasterData()
  }
   
  getAllNote(){
    this.globalService.showHideLoader('Loader_GetAllNotes')
    this.notesService.getAllNote().subscribe(res => {
      this.globalService.showHideLoader('Loader_GetAllNotes',false)
      if(res.status){
        this.dataSource = res.data
      } 
    },err=>{
      this.globalService.showHideLoader('Loader_GetAllNotes',false)
    });
  }


  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ngAfterViewInit() {
    
  }
  addNewNote(){
    this.new_note_title = this.new_note_title.trim()
    if(this.new_note_title.length>0){
      this.globalService.showHideLoader('Loader_AddNewNote')
      let postData = {note_title:this.new_note_title}
      this.notesService.addNewNote(postData).subscribe(res => {
        this.globalService.showHideLoader('Loader_AddNewNote',false)
        if(res.status){
          this.router.navigate([`/notes/note/${res.data.id}`])
        } 
      },err=>{
        this.globalService.showHideLoader('Loader_AddNewNote',false)
      });
    }
    
  }

  noteChangeStatus(noteObj:any,statusObj:any){
    if(confirm(statusObj.confirm_msg)){
      let postData:any = {}
      postData.id = noteObj.id
      postData.status_id = statusObj.id
      this.globalService.showHideLoader('Loader_noteChangeStatus')
      this.notesService.noteChangeStatus(postData).subscribe(res => {
        this.globalService.showHideLoader('Loader_noteChangeStatus',false)
        if(res.status){
          noteObj.status_id = statusObj.id
          noteObj.status = statusObj.name
          noteObj.color = statusObj.color
        } 
      },err=>{
        this.globalService.showHideLoader('Loader_noteChangeStatus',false)
      });
    }
  }
  noteDelete(note_id:number,status_id:number){

  }
  editNote(note_id:number): void {
    this.router.navigate([`/notes/note/${note_id}/0`])
  }
  previewNote(note_id:number): void {
    this.router.navigate([`/notes/preview/${note_id}/0`])
  }

  
  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
    
    }
  
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() {
      
    }
  
    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: PeriodicElement) {
      
    }
}
