import {Component, Directive, Input, ViewChild, AfterViewInit} from '@angular/core';
import {FormControl,FormGroup,Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {MatPaginator , PageEvent} from '@angular/material/paginator';

import { animate, style, transition, trigger, state } from '@angular/animations';

import { NotesService } from 'src/app/services/notes.service';
import { GlobalService } from 'src/app/services/global.service';
 
import { AddEditNotePageModel } from 'src/app/models/requests/notes/add-edit-note-page';
import { Location } from '@angular/common';


@Component({
  selector: 'app-notes-add-edit',
  templateUrl: './notes-add-edit.component.html',
  styleUrls: ['./notes-add-edit.component.scss'],
  animations: [
      trigger(
            'inOutAnimation',
        [ state('open', style({
            
          opacity: 1,
        })),
        state('closed', style({
          width: '24px',
          //opacity: 0,
        })),
        transition('* => closed', [
          animate('0.2s')
        ]),
        transition('* => open', [
          animate('0.2s')
        ]),]
      ),
    
      trigger(
        'inOutAnimationRightPanel',
    [ state('c2wowOpenRightPnl', style({
        
      opacity: 1,
    })),
    state('c2wowCloseRightPnl', style({
      width: '0px',
      opacity: 0,
    })),
    transition('* => c2wowCloseRightPnl', [
      animate('0.2s')
    ]),
    transition('* => c2wowOpenRightPnl', [
      animate('0.2s')
    ]),])
    
    ],
      styles: [`:host {display: flex;}`]
  })
 
export class NotesAddEditComponent implements AfterViewInit {
 

  smnConfig = {
    placeholder: 'Notes',
    tabsize: 1,
    height:750,
    toolbar: [
      ['style', ['bold', 'italic']], //Specific toolbar display
      ['color', ['color']],
      ['fontsize', ['fontsize']],
      ['para', ['ul', 'ol', 'paragraph']]
    ],
    dialogsInBody: true,
    styleTags: [
      'p',
      { title: 'Blockquote', tag: 'blockquote', className: 'blockquote', value: 'blockquote' },
      'pre', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'
    ],
  }

  currentNotePageFrom = new FormGroup({
    page_title: new FormControl('',[Validators.required]),
    note_title: new FormControl('',[Validators.required])
  });

  previewContent = false
  panelOpenState = true;

  note_id:any = 0
  page_id:any = 0
  next_page_id:any = 0

  note_data:any = {}
  note_pages:any[] =[]
  
  currentPageIndex = 0;

  tokenLength = 10
  topicSearcReq = ''
  topicSearcRes = '';

  currentNotePageObj:any = { 
    id:0,
    note_title: '',
    page_title: '',
    page_content:''
  }
  
  


  @ViewChild(MatPaginator)
  notePaginator!: MatPaginator;
  
  constructor(private router: Router,private location:Location,public globalService:GlobalService, private notesService:NotesService,private activatedRoute:ActivatedRoute){
    
    activatedRoute.paramMap.subscribe(paramMap => { 
      this.note_id = Number(paramMap.get('note_id'))||0; 
      this.page_id = paramMap.get('page_id')!=null&&Number(paramMap.get('page_id'))>-1?Number(paramMap.get('page_id')):-1; 
      if(this.page_id<0){
        this.router.navigate([`/notes/note/${this.note_id}/0`])
      }else{
        this.getNoteData()
      }    
    });
   


  }
  ngAfterViewInit(){
  }

  hide = false;
  hideRightPnl = false

  triggerAnimation() {
    this.hideRightPnl = false
    this.hide = !this.hide;
  }

  triggerRightPnl() {
    this.hide = false
    this.hideRightPnl = !this.hideRightPnl
  }
 
  tokenLengthLbl(value: number): string {
    return `${value}`;
  }

  handlePageEvent(e: PageEvent) {
    //this.pageEvent = e;
    //this.length = e.length;
    //this.pageSize = e.pageSize;
    this.currentPageIndex = e.pageIndex;
    console.log(this.note_pages)
  }

  previewNote(){
    this.router.navigate([`/notes/preview/${this.note_id}/${this.page_id}`])
  }

  deletePage(){
    if(this.note_pages.length>1){
      if(this.note_pages.length == this.currentPageIndex+1){
        this.notePaginator.previousPage();
      }
      this.note_pages.splice(this.currentPageIndex, 1);
    }
  }

  
  getNoteData(){
    if(this.note_id>0){
      this.globalService.showHideLoader('Loader_GetNoteAllData')
      this.notesService.getNote(this.note_id).subscribe(res => {
        this.globalService.showHideLoader('Loader_GetNoteAllData',false)
        if(res.status){
          this.note_data = res.data.note
          this.note_pages = res.data.note_pages
         // this.note_data = this.globalService.cloneDeep(this.note_data_server)
          this.setPageId()
          this.selectNotePage()
        } 
      },err=>{
        this.globalService.showHideLoader('Loader_GetNoteAllData',false)
      });
    }
  }

  
  setPageId(inpPageId:any=0){
    let setFirst = true;
    if(inpPageId>0){
      this.page_id = inpPageId
    }
    if(this.page_id>0&&this.note_pages.length>0){
      if(this.note_pages.find(itm => itm.id == this.page_id)){
          setFirst = false
      }
    }
    if(setFirst){
      this.page_id = 0
      if(this.note_pages.length>0){
        this.page_id = this.note_pages[0].id
      }
    }
    
    let matchChk = false
    let stopChk = false
    this.next_page_id = 0
    this.note_pages.forEach(itm =>{
      if(stopChk){
        return
      }
      if(itm.id != this.page_id){
        this.next_page_id = itm.id
        if(matchChk){
          stopChk = true
        }
      }else{
        matchChk = true
      }      
    })
    
    this.location.replaceState(`/notes/note/${this.note_id}/${this.page_id}`);
    //this.router.navigate(['/notes/note/', this.note_id,this.page_id]);
  }

  addNewPage(atLast:any=true){
    this.globalService.showHideLoader('Loader_AddNewNotePage')
    let postData:any = {at_last:atLast}
    postData.note_id=this.note_id
    this.notesService.addNewPage(postData).subscribe(res => {
      this.globalService.showHideLoader('Loader_AddNewNotePage',false)
      if(res.status){
        this.note_pages = res.data.note_pages
        this.selectNotePage(res.data.page_id)
      } 
    },err=>{
      this.globalService.showHideLoader('Loader_AddNewNotePage',false)
    });
}

  pageAddUpdate(){
    
    this.currentNotePageFrom.markAllAsTouched()
    // if(this.currentNotePageFrom.valid){
    this.globalService.showHideLoader('Loader_AddNewNotePage')
    let postData:any = this.currentNotePageObj //this.currentPageFrom.getRawValue()
    postData = this.globalService.serializeObject(postData,AddEditNotePageModel)
    postData.note_id=this.note_id
    postData.id=this.page_id
    this.notesService.pageAddUpdate(postData).subscribe(res => {
      this.globalService.showHideLoader('Loader_AddNewNotePage',false)
      if(res.status){
        this.note_pages = res.data.note_pages
      } 
    },err=>{
      this.globalService.showHideLoader('Loader_AddNewNotePage',false)
    });
    //}
  }

  selectNotePage(inpPageId:any=0){
    if(inpPageId>0){
      this.setPageId(inpPageId)
    }
    let notePage = this.note_pages.find(itm => itm.id == this.page_id)
    this.currentNotePageObj = this.globalService.cloneDeep(notePage)
  
  }

  deleteNotePage(inpNotePageId:number=0,event:any=false){
    if(event){
      event.stopPropagation();
    }
    let deletePageId:number = this.page_id
    let confirmMsg = 'Do you want to delete current page?';
    if(inpNotePageId>0){
      deletePageId = inpNotePageId
      confirmMsg = 'Do you want to delete selected page?';
    } 

    if(deletePageId>0&&confirm(confirmMsg)){
      this.globalService.showHideLoader('Loader_DeleteNotePage')
      this.notesService.deleteNotePage(deletePageId).subscribe(res => {
        this.globalService.showHideLoader('Loader_DeleteNotePage',false)
        if(res.status){
          if(deletePageId == this.page_id){
            this.page_id = this.next_page_id
          }
          
          this.getNoteData()
        } 
      },err=>{
        this.globalService.showHideLoader('Loader_DeleteNotePage',false)
      });
    }
  }

  
  aiHelpSearch(){
     let aiSrchStr = this.topicSearcReq.trim()
    if(aiSrchStr.length>0){
      this.globalService.showHideLoader('Loader_AiHelpSearch')
      this.notesService.aiHelpSerach(aiSrchStr).subscribe(res => {
        this.globalService.showHideLoader('Loader_AiHelpSearch',false)
        if(res.status){
         this.topicSearcRes = res.data
        } 
      },err=>{
        this.globalService.showHideLoader('Loader_AiHelpSearch',false)
      });
    }
  }
}
