import { Injectable,EventEmitter } from '@angular/core';

import * as moment  from 'moment'

@Injectable({
  providedIn: 'root'
})
export class JwtService {
  eventEmitterUser: EventEmitter<any> = new EventEmitter();
  constructor() { }

  setSession(authResult:any) {
    authResult.expiresIn = 3000;
    const expiresAt = moment().add(authResult.expiresIn,'second');
    localStorage.setItem('id_token', authResult.token);
    localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );
    localStorage.setItem("udata", JSON.stringify(authResult) );
    this.getUserDetail()
  }
  getUserDetail(){
    let udata:any = {};
    try{
      if(this.isLoggedIn()){
        udata =  JSON.parse(localStorage.getItem("udata")|| '{}');
      }
    }catch(err){

    }
    this.eventEmitterUser.next({resType:'userDetails',data:udata});
  }
  
  logout() {
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("udata");
    this.getUserDetail();
  }

  public isLoggedIn() {
    let loginStatus = false
    const udata:any = JSON.parse(localStorage.getItem("udata")|| '{}');
    if(udata['token']){
      loginStatus = true
    }
    return loginStatus 
  } 

  public getToken():string{
    return localStorage.getItem("id_token")||'';
  }

}
