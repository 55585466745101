<div class="container-fluid page">

  
  <!--div class="d-flex page__box p-3 mt-2">
    <div class="page-heading-title"><p class="page-heading-title-p">Notes</p>
    </div>
    
    <div class="page-heading-actions right">
      <button (click)="openDialog()" mat-raised-button>Add</button>
    </div>
  </div-->

  <div class="row p-md-3 page__box1">
    <div class="d-none d-md-block col-md-1">
      Notes
    </div>
    <div class="col-8 offset-1 col-md-4 offset-md-4 p-1 p-md-2">
      <div class="form-group">
        <input [(ngModel)]="new_note_title" type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="New Note">
        
      </div>
       
    </div>
    <div class="col-3 col-md-2 p-1 p-md-2">
      <button (click)="addNewNote()" mat-raised-button>New</button>
      
    </div>
</div>
<div class="container-fluid p-0 p-md-4 mt-1 ">
  <div class="row">
    <div class="col-12 p-0">
    <table class="table table-hover table-sm">
      <thead>
        <tr>
          <th scope="col"><input type="checkbox"/></th>
          <th scope="col">Title</th>
          <th scope="col">Pages</th>
          <th class="d-none d-md-table-cell" scope="col">Created</th>
          <th class="d-none d-md-table-cell" scope="col">Published</th>
          <th scope="col">Status</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let noteItm of dataSource;let i = index" (click)="editNote(noteItm.id)" >
          <th scope="row"><input type="checkbox"/></th>
          <td>{{noteItm.note_title}} </td>
          <td>{{noteItm.pages}}</td>
          <td  class="d-none d-md-table-cell">{{noteItm.created_at|date: "dd/MM/yyyy"}}</td>
          <td  class="d-none d-md-table-cell">{{noteItm.updated_at|date: "dd/MM/yyyy"}}</td>
          <td (click)="$event.stopPropagation()">

            <span class="c2wow-pointer" [matMenuTriggerFor]="c2wowNotesStatus"><span class="c2-circle" [style.background-color]="'#'+noteItm.color"> </span>{{noteItm.status}}</span>
            <mat-menu #c2wowNotesStatus="matMenu">
              <ng-container  *ngFor="let stm of masterData?.mstr_note_status;">
                <button   *ngIf="stm.id!=noteItm.status_id" (click)="noteChangeStatus(noteItm,stm)"  mat-menu-item>  
                  <span class="c2-circle" [style.background-color]="'#'+stm.color"></span><span>{{stm.name}}</span>
                </button>
              </ng-container>
              
              
              <!--button mat-menu-item>Item 2</button-->
            </mat-menu>  
          </td>
          <td>
            <button (click)="$event.stopPropagation()" mat-button [matMenuTriggerFor]="c2wowNotesListAction" color="primary"><mat-icon>more_vert</mat-icon></button>
            <mat-menu #c2wowNotesListAction="matMenu">
              <button  (click)="editNote(noteItm.id)"   mat-menu-item>  
                <mat-icon color="primary" >edit</mat-icon>
                <span>Edit</span>
              </button>
              <button  (click)="previewNote(noteItm.id)" mat-menu-item>  
                <mat-icon color="primary">article</mat-icon>
                <span>Preview</span>
              </button>
               
              <!--button mat-menu-item>Item 2</button-->
            </mat-menu>
          </td>
        </tr>
   
    
      </tbody>
    </table>
    </div>
 
      <div class="col-12">
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </div>
    
  </div>

</div>