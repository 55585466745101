import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtService } from '../jwt.service'
import { takeUntil } from 'rxjs';
 
 

@Injectable({
  providedIn: 'root'
})
export class LoginResolver {
 
  constructor(private jwtService:JwtService, private router:Router) { 

  }

  resolve(): any {
    let isAuthUser: boolean = this.jwtService.isLoggedIn()
    if(!isAuthUser){
      this.router.navigate(['/'])
    }
  }  
}
