import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { HttpHeaders, HttpClient, HttpResponse, HttpParams, HttpParamsOptions } from '@angular/common/http';

import { NgxUiLoaderService } from "ngx-ui-loader";
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  eventEmitterHeaderSearch: EventEmitter<any> = new EventEmitter();

  constructor(private apiService:ApiService, private ngxUiLoaderService:NgxUiLoaderService) { }

  mainSerach(search: string) {
    // let params = new HttpParams()
    // params = params.append('search',search)

    let params:any = {'search':search};
    this.apiService.get('/home/search',params).subscribe(res => {
      this.ngxUiLoaderService.stop('mainSearchLoader');
      if(res.status)
      this.eventEmitterHeaderSearch.next({resType:'createCompletion',data:res.data});
    },err => {
      this.ngxUiLoaderService.stop('mainSearchLoader');
    });
  }

  imgWork(search: string) {
    let params:any = {'search':search};
    this.apiService.get('/home/artwork',params).subscribe(res => {
      this.ngxUiLoaderService.stop('mainSearchLoader');
      if(res.status)
      this.eventEmitterHeaderSearch.next({resType:'aiImage',data:res.data});
    },err =>{
      this.ngxUiLoaderService.stop('mainSearchLoader');
    });
  }

  serachQuotes(search: string) {
    // let params = new HttpParams()
    // params = params.append('search',search)
    let params:any = {'search':search};
    this.apiService.get('/home/serachquotes',params).subscribe(res => {
      this.ngxUiLoaderService.stop('searchQuoteLoader');
      if(res.status)
      this.eventEmitterHeaderSearch.next({resType:'searchQuotesHome',data:res.data});
    },err => {
      this.ngxUiLoaderService.stop('searchQuoteLoader');
    });
  }
}
