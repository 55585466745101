<mat-drawer-container [hasBackdrop]="true">
    <mat-drawer mode="side" [opened]="sideBarOpen" position="start" [mode]="'over'">
      <app-sidenav></app-sidenav>
    </mat-drawer>
    <mat-drawer-content>
      <app-header (toggleSidebarForMe)="sideBarToggler($event)"></app-header>
      <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>
<ngx-ui-loader blur="6" text="c2wow" logoUrl1 = "./assets/img/logo.png" fgsType= "three-strings"></ngx-ui-loader>
