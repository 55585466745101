<div class="container-fluid page">
    <div class="container-fluid mt-1 mt-md-2 ">
          <div class="stickyPanelLeft">
              
              <div [@inOutAnimation]="hide ? 'open' : 'closed'" class="c2wow-pages-list p-0" *ngIf="true">
                
                <ul  class="mt-2 list-group" style="width:24px">
                    
                  <li  class="list-group-item list-group-item-secondary" >
                    <mat-icon color="primary"  class="c2wow-pointer"  (click)="editNote()" matSuffix>edit_square</mat-icon>
                    <mat-icon color="primary"  class="c2wow-pointer" (click)="triggerAnimation()" *ngIf="hide" matPrefix>arrow_back_ios</mat-icon>
                    <mat-icon color="primary"  class="c2wow-pointer" (click)="triggerAnimation()" *ngIf="!hide" matPrefix>arrow_forward_ios</mat-icon>
                  </li>
                </ul>
              
                <ul  class="mt-2 list-group"  style="max-height:300px;overflow-y: scroll;overflow-x:hidden">              
                  <li *ngFor="let c2wowPg of note_pages; let pgIndx = index" 
                  class="list-group-item list-group-item-primary d-flex justify-content-between" 
                  style="min-width:200px"
                  [ngClass]="{'active':c2wowPg.id == page_id}"
                  (click)="selectNotePage(c2wowPg.id)">
                    <span class="float-left" *ngIf="!hide">{{pgIndx+1}}</span>
                    <span class="py-md-3 float-left" *ngIf="hide">{{pgIndx+1}} {{c2wowPg.page_title.trim()!=''?c2wowPg.page_title.trim():'Page '}}   
                    </span>    
                  </li>
                
                  
                </ul>
              </div>
    
    
          </div>  
           
    <div class="container pr-0 mt-1 mt-md-2 ">
      <div class="row">

        <div class="p-0 col-12"  id = "MainContent">
          <div class="row">
            <div class="col-6 col-md-2 order-1 order-md-1">
                <div class="logo1 py-2">
                    <a routerLink="/home"><img src="./assets/img/avtr_ai.png" alt="c2wow" /></a>
                  </div>
            </div>
            <div class="col-12 col-md-5 mt-3 mt-md-1 order-3 order-md-2">
                <div *ngIf="currentNotePageObj.page_title.trim().length>0" class="c2wow-title p-3">
                 
                    {{currentNotePageObj.page_title?currentNotePageObj.page_title:''}}
                
            </div>
            </div>
            <div class="col-6 col-md-2 p-2 mt-1 p-md-2 order-2 order-md-3">
              <button (click)="printDoc()"  mat-raised-button>PDF</button>
            </div>
          </div>
          <div class="row mt-3" id="c2wow-print" >
            <div class="col-12 p-0">
              <app-preview-doc  [htmlContent]="currentNotePageObj.page_content"></app-preview-doc>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div> 
</div>