      <!-- guarantee -->
     <div class="full_bg pt-2">
      <div class="container">
         <div class="row pt-md-4">
            <div class="pr-md-5 col-md-7">
               <div id="ho_co" class="guarantee-box_main">
                  <div class="c2wow-home-service guarantee-box text_align_center">
                     
                     <h1>We guarantee  to <span class="blue_light">quality</span></h1>
                     <p class="d-md-none"> Notes Wiz :- This platform provides a comprehensive suite of tools to help teachers, writers, and professionals write, maintain, and share content.</p>
                     <p class="d-none d-md-block"> This platform provides a comprehensive suite of tools to help teachers, writers, and professionals write, maintain, and share content. It offers a range of features such as advanced text editing, collaboration tools, and content management capabilities. It also provides a secure platform for storing and sharing content, allowing users to easily access and share their work with others. Additionally, the platform offers a range of analytics and reporting tools to help users track and measure the performance of their content.
                        We are continuously working to improve the platform by adding new features and capabilities. Our goal is to make the platform more user-friendly and efficient, so that users can get the most out of their content creation and sharing experience. We are also working on developing advanced features that will make the platform even more powerful and useful.
                     </p>
                   </div>
                  <a class="read_more" routerLink="/auth">Get Started</a>
               </div>
            </div>

            <div class="pl-md-5 col-md-5">
               <img src="./assets/images/NotesWiz.png" />
            </div>
           
           
             
         </div>
        
      </div>
   </div>
   <!-- end guarantee -->
 