import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { environment } from './../environments/environment';
import { GlobalService } from './services/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'c2wow';
  sideBarOpen = false;

  constructor(private el:ElementRef,public globalService:GlobalService,){
      console.log('production:- ',environment.env); // Logs false for development environment
      globalService.fetchMasterData()
  }
  //@HostListener('click', ['$event.target'])
  onClick(target:any){
    console.log(target)
    this.sideBarOpen = false;
  }
  sideBarToggler($event:any) {
    if($event['toggleSidebar']){
      this.sideBarOpen = !this.sideBarOpen;
    }
    if($event['closeSidebar']){
      this.sideBarOpen = false;
    }
    
  }
  closeMenu(){
    this.sideBarOpen = false;
  }
}
