<div class="container-fluid page">
    
    <!--div class="row p-3 page__box">
        <div class="col-sm-2"><p class="page-heading-title-p">Notes</p>
        </div>
        <div class="col-md-1 col-sm-1  offset-md-9 offset-sm-8 p-md-1"></div>
    </div-->
  

    <div class="container-flude pt-3 pb-3 ">
      <div class="row result-list"  *ngIf="aiResponse">
        <!-- Main content -->
        <div class="col-md-2">
        </div>
        <div class="col-md-1 c2wow-avatar-container p-3">
          <div class="avatar">
            <img [src]='aiResponseImg'  alt="AI Answer" />
          </div>
        </div>
        
        <div class="col-md-5 c2wow-content-box ai-result-content p-3"  [innerHtml]="this.aiResponse|nl2br">
        </div>

        <div class="col-md-2 c2wow-options-container p-3">
          <div class="c2wow-options">
            <button [cdkCopyToClipboard]="this.aiResponse" mat-icon-button color="primary"  aria-label="Example icon button with a open in new tab icon">
              <mat-icon>content_copy</mat-icon>
            </button>
            
            <button (click)="shareContent(this.aiResponse)" mat-icon-button color="primary" aria-label="Example icon button with a heart icon">
              <mat-icon>share</mat-icon>
            </button>
           
          </div>
        </div>
      </div>
    </div>


    
    <div class="row result-list pb-3"   *ngFor="let qtItm of searchQuotes">
      <div class="col-0 col-md-2 order-1 "></div>
      <div class="col-2 col-md-1 c2wow-avatar-container p-3 order-2">
        <div class="avatar">
          <img src="https://bootdey.com/img/Content/avatar/avatar5.png" alt="Company" />
        </div>
      </div>
      <div class="col-12  col-md-5 order-5 order-md-3 c2wow-content-box">
        <div class="result-content p-3" [innerText]="qtItm.content">
        </div>
      </div>

      <div class="col-3 p-3 p-md-0 col-md-2 c2wow-options-container order-3  order-md-4">
        <div class="c2wow-options">
          <button [cdkCopyToClipboard]="qtItm.content" mat-icon-button color="primary"  aria-label="Example icon button with a open in new tab icon">
            <mat-icon>content_copy</mat-icon>
          </button>
          
          <button (click)="shareContent(qtItm.content)" mat-icon-button color="primary" aria-label="Example icon button with a heart icon">
            <mat-icon>share</mat-icon>
          </button>
         
        </div>
      </div>
      <div class="col-7 col-md-2 p-3 order-4  order-md-5">
        <mat-chip color="blue">note</mat-chip>
      </div>
       
    </div>

 
 
</div>
  




    <!--div class="row result-list pb-3">
      <div class="col-md-2"></div>
      <div class="col-md-1 c2wow-avatar-container">
        <div class="avatar">
          <img src="https://bootdey.com/img/Content/avatar/avatar5.png" alt="Company" />
        </div>
      </div>
      <div class="c2wow-content-box col-md-5">
        <div class="result-content">
          
          Senior Software Engineer / Developer Senior Software Engineer / Developer Senior Software Engineer / Developer Senior Software Engineer / Developer 
         
        </div>
      </div>

      
      <div class="col-md-2 c2wow-options-container">
        <div class="c2wow-options">
          <button mat-icon-button color="primary"  aria-label="Example icon button with a open in new tab icon">
            <mat-icon>content_copy</mat-icon>
          </button>
          
          <button (click)="shareContent('First Share')" mat-icon-button color="primary" aria-label="Example icon button with a heart icon">
            <mat-icon>movie_edit</mat-icon>
            <mat-icon>share</mat-icon>
          </button>
         
        </div>
      </div>
      <div class="col-md-2">
        <mat-chip color="blue">c2wow</mat-chip>
      </div>
       
    </div-->
