import { Component,ViewEncapsulation,Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
 

@Component({
  selector: 'app-preview-doc',
  templateUrl: './preview-doc.component.html',
  styleUrls: ['./preview-doc.component.scss']
})
export class PreviewDocComponent {
    @Input() htmlContent = '';
    constructor(public sanitizer: DomSanitizer){}
    ngAfterViewInit(){
    }
}
