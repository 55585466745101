import { JsonObject, JsonProperty } from "json2typescript";
import { DateConverter,NullableString, NullableInt, NumberConvert, NumberStringConvert, BooleanConvert } from "../../custom-json-to-types";


@JsonObject('ReqEN_AddEditNotePageModel')
export class AddEditNotePageModel {
    @JsonProperty("id",NumberConvert,true)
    id: number|undefined = undefined;

    @JsonProperty("note_id",Number,true)
    note_id: Number = 0

    @JsonProperty("page_title",String)
    page_title: String = ''

    @JsonProperty("page_content",String)
    page_content: String = ''

}
