import {Component, Directive, Input, ViewChild, AfterViewInit} from '@angular/core';
import {FormControl,FormGroup,Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {MatPaginator , PageEvent} from '@angular/material/paginator';

import { animate, style, transition, trigger, state } from '@angular/animations';

import { NotesService } from 'src/app/services/notes.service';
import { GlobalService } from 'src/app/services/global.service';
 
import { AddEditNotePageModel } from 'src/app/models/requests/notes/add-edit-note-page';
import { Location } from '@angular/common';

const windowObj = window
@Component({
  selector: 'app-note-preview',
  templateUrl: './note-preview.component.html',
  styleUrls: ['./note-preview.component.scss'],
  animations: [
    trigger(
          'inOutAnimation',
      [ state('open', style({
          
        opacity: 1,
      })),
      state('closed', style({
        width: '24px',
        //opacity: 0,
      })),
      transition('* => closed', [
        animate('0.2s')
      ]),
      transition('* => open', [
        animate('0.2s')
      ]),]
    )]
})
export class NotePreviewComponent {
  windowObjL = windowObj
  panelOpenState = true;

  note_id:any = 0
  page_id:any = 0
  next_page_id:any = 0

  note_data:any = {}
  note_pages:any[] =[]
  
  currentPageIndex = 0;


  currentNotePageObj:any = { 
    id:0,
    note_title: '',
    page_title: '',
    page_content:''
  }

  
  constructor(private router: Router,private location:Location,public globalService:GlobalService,
     private notesService:NotesService,private activatedRoute:ActivatedRoute){
    
    activatedRoute.paramMap.subscribe(paramMap => { 
      this.note_id = Number(paramMap.get('note_id'))||0; 
      this.page_id = paramMap.get('page_id')!=null&&Number(paramMap.get('page_id'))>-1?Number(paramMap.get('page_id')):-1; 
      if(this.page_id<0){
        this.router.navigate([`/notes/preview/${this.note_id}/0`])
      }else{
        this.getNoteData()
      }    
    });

  }
  ngAfterViewInit(){
  }

  hide = false;
  hideRightPnl = false

  triggerAnimation() {
    this.hideRightPnl = false
    this.hide = !this.hide;
  }

  triggerRightPnl() {
    this.hide = false
    this.hideRightPnl = !this.hideRightPnl
  }
 
  tokenLengthLbl(value: number): string {
    return `${value}`;
  }

  handlePageEvent(e: PageEvent) {
    //this.pageEvent = e;
    //this.length = e.length;
    //this.pageSize = e.pageSize;
    this.currentPageIndex = e.pageIndex;
    console.log(this.note_pages)
  }


  editNote(){
    this.router.navigate([`/notes/note/${this.note_id}/${this.page_id}`])
  }
  getNoteData(){
    if(this.note_id>0){
      this.globalService.showHideLoader('Loader_GetNoteAllData')
      this.notesService.getNotePreview(this.note_id).subscribe(res => {
        this.globalService.showHideLoader('Loader_GetNoteAllData',false)
        if(res.status){
          this.note_data = res.data.note
          this.note_pages = res.data.note_pages
         // this.note_data = this.globalService.cloneDeep(this.note_data_server)
          this.setPageId()
          this.selectNotePage()
        } 
      },err=>{
        this.globalService.showHideLoader('Loader_GetNoteAllData',false)
      });
    }
  }

  
  setPageId(inpPageId:any=0){
    let setFirst = true;
    if(inpPageId>0){
      this.page_id = inpPageId
    }
    if(this.page_id>0&&this.note_pages.length>0){
      if(this.note_pages.find(itm => itm.id == this.page_id)){
          setFirst = false
      }
    }
    if(setFirst){
      this.page_id = 0
      if(this.note_pages.length>0){
        this.page_id = this.note_pages[0].id
      }
    }
    
    let matchChk = false
    let stopChk = false
    this.next_page_id = 0
    this.note_pages.forEach(itm =>{
      if(stopChk){
        return
      }
      if(itm.id != this.page_id){
        this.next_page_id = itm.id
        if(matchChk){
          stopChk = true
        }
      }else{
        matchChk = true
      }      
    })
    
    this.location.replaceState(`/notes/preview/${this.note_id}/${this.page_id}`);
    //this.router.navigate(['/notes/note/', this.note_id,this.page_id]);
  }


  selectNotePage(inpPageId:any=0){
    if(inpPageId>0){
      this.setPageId(inpPageId)
    }
    let notePage = this.note_pages.find(itm => itm.id == this.page_id)
    this.currentNotePageObj = this.globalService.cloneDeep(notePage)
  
  }

  printDoc(){
    // @ts-ignore
    let dataHtml =  this.currentNotePageObj
    //this.windowObjL.print()
    // @ts-ignore

    let str = ``;
    this.note_pages.forEach(pageItm =>{
      if(str!=''){
        str +=  `<div style = "display:block; clear:both; page-break-after:always;"></div>`;
      }
        str +=  `<div class="c2wow-page-content">              
                    ${pageItm.page_content}     
                  </div>`
    })
    // let str = `<html><head><title></title></head><body >${dataHtml}</body></html>`;



    let str1 = `<html>
    <head>
        <title>c2wow-print Document</title>

        <style>
          .page-container {
            width: 90%;
            margin-right: 5%;
            margin-left: 5%;
            padding: 1%;
            border: solid;
            border-width: 0px;
            margin-top: 3%;
            border-color: #ccc;
        }
        .c2wow-page-header{
            display: flex;
        }
        .c2wow-logo{
            width: 130px;
            height: 45px;
        }
        .c2wow-page-tilte{
            font-size: 20px;
            color: #1e55b3;
            font-weight: 800;  
            padding: 1%;
            margin-left: 30%;             
        }
        .c2wow-page-content {
          margin-top: 2%;
        }
        </style>
    </head>
    <body>
        <div class="page-container">
          <div class="c2wow-page-header">
              <img class = "c2wow-logo" src="http://localhost:4200/assets/img/avtr_ai.png">
              <div class="c2wow-page-tilte"> ${dataHtml.page_title} </div>
          </div>  
              ${str}       
        </div>
    </body>
  </html>`;

      var mywindow = this.windowObjL.open('', 'new div', 'height=900,width=1600');
      // @ts-ignore
      mywindow.document.write(str1);
      // @ts-ignore
      mywindow.document.close();
      // @ts-ignore
      mywindow.focus();
      // @ts-ignore
      //mywindow.print();
      setTimeout(function(){mywindow.print();},100);
      // @ts-ignore
      //mywindow.close();


  }
}
