import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesRoutingModule } from './notes-routing.module';
import { NotesListComponent } from './notes-list/notes-list.component';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';
import { NotesAddEditComponent } from './notes-add-edit/notes-add-edit.component'
import { NgxSummernoteModule } from 'ngx-summernote';
import { NotePreviewComponent } from './note-preview/note-preview.component';
//import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    NotesListComponent,
    NotesAddEditComponent,
    NotePreviewComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NotesRoutingModule,
    NgxSummernoteModule,
    SharedModule
   // HttpClientModule
  ],
  exports:[
    NotesListComponent
  ]
})
export class NotesModule { }
