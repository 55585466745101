<!-- about -->
<div class="about">
    <div class="container">
       <div class="row">
          <div class="col-md-12">
             <div class="titlepage text_align_center">
                <h2>About <span class="blue_light">Comapny</span></h2>
             </div>
          </div>
          <div class="col-md-10 offset-md-1">
             <div class="about_img text_align_center">
                <p>
                <a routerLink="/home">C2wow.com</a> provides a comprehensive suite of tools to help teachers, writers, and professionals write, maintain, and share content. It offers a range of features such as advanced text editing, collaboration tools, and content management capabilities. It also provides a secure platform for storing and sharing content, allowing users to easily access and share their work with others. Additionally, the platform offers a range of analytics and reporting tools to help users track and measure the performance of their content. We are continuously working to improve the platform by adding new features and capabilities. Our goal is to make the platform more user-friendly and efficient, so that users can get the most out of their content creation and sharing experience. We are also working on developing advanced features that will make the platform even more powerful and useful.
                </p>
             </div>
          </div>
       </div>
    </div>
 </div>
 <!-- end about -->