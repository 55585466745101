import { EventEmitter, Injectable } from '@angular/core';
import { JsonConvert, OperationMode, ValueCheckingMode } from "json2typescript"
import { NgxUiLoaderService } from "ngx-ui-loader";

import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  eventEmitterMasterData: EventEmitter<any> = new EventEmitter();
  public jsonConvert: JsonConvert = new JsonConvert()
  public jsonValidate: JsonConvert = new JsonConvert()
  constructor(private _snackBar: MatSnackBar, private ngxUiLoaderService:NgxUiLoaderService, private apiService:ApiService ) { 
    this.jsonConvert.operationMode = OperationMode.LOGGING; // print some debug data
    this.jsonConvert.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
    this.jsonConvert.valueCheckingMode = ValueCheckingMode.DISALLOW_NULL; 

    this.jsonValidate.operationMode = OperationMode.LOGGING; // print some debug data
    this.jsonValidate.ignorePrimitiveChecks = false; // don't allow assigning number to string etc.
    this.jsonValidate.valueCheckingMode = ValueCheckingMode.DISALLOW_NULL; 
    
  }

  //Class to JSON
  serializeObject(jsonObject:any,inpModel:any){
    let outData
    try {
      outData = this.jsonConvert.serializeObject(jsonObject, inpModel);
    } catch (e) {
        console.log((<Error>e));
    }
    return outData;
  }

  //JSON to Class
  deserializeObject(jsonObject:any,inpModel:any){
    let outData
    try {
      outData = this.jsonConvert.deserializeObject(jsonObject, inpModel);
    } catch (e) {
      debugger
        console.log((<Error>e));
    }
    return outData;
  }

  //JSON to Class
  validateObject(jsonObject:any,inpModel:any){
    let isvalid = false
    debugger
    try {
      this.jsonConvert.deserializeObject(jsonObject, inpModel);
      isvalid = true
    } catch (e) {
        console.log((<Error>e));
    }
    return isvalid;
  }

  allErrorsList:any[] = []
  showErrorTimeOut:any=false
  showError(err:string=''){
    
    let thisObj = this
    //debugger
    if(!thisObj.showErrorTimeOut){
      console.log('TIMER')
      thisObj.showErrorTimeOut = true
      this._snackBar.open(err, 'Close', {
        horizontalPosition: 'left',
        verticalPosition: 'top',
        duration: 2000,
      });

      setTimeout(function(){
        let errAll = '';
        thisObj.showErrorTimeOut = false
        if(thisObj.allErrorsList.length==0)
        return

        thisObj.allErrorsList.forEach(itmErr=>{
          errAll += itmErr +"\r\n"
        })
        thisObj.allErrorsList = []
        thisObj.showError(errAll)
      },2000)
    }else{
      console.log('PUSH')
      thisObj.allErrorsList.push(err);
    }
  }

  cloneDeep(inpData:any){
    return JSON.parse(JSON.stringify(inpData))
  }

  showHideLoader(loaderIdentifier:string,show:any=true){
    if(show){
      this.ngxUiLoaderService.start(loaderIdentifier);
    }else{
      this.ngxUiLoaderService.stop(loaderIdentifier);
    }
  
  }

  masterData:any = false
  fetchMasterData() {
    this.ngxUiLoaderService.start('LoadMasterDataLoader');
    this.apiService.get('/mstr/data').subscribe(res => {
      this.ngxUiLoaderService.stop('LoadMasterDataLoader');
      if(res.status){
        this.masterData = res.data
        this.getMasterData()
      }
    },err => {
      this.ngxUiLoaderService.stop('LoadMasterDataLoader');
    });
  }

  getMasterData(){
    if(!this.masterData){
      this.fetchMasterData()
    }else{
      this.eventEmitterMasterData.next(this.masterData);
    }
  }

  contactUs(params: any) {
    return this.apiService.post('/home/contactus',params)
  }


  //sidepane service start
  emitterSidebarMainMenu: EventEmitter<any> = new EventEmitter();
  sidebarMainMenu(params: any) {
    return this.apiService.post('/home/contactus',params)
  }
  //sidepane service end
}
