<div class="container-fluid page">


 
<!--div class="row p-3 page__box">
  <div class="col-sm-2"><p class="page-heading-title-p">Notes</p>
  </div>
      
  <div class="col-md-1 col-sm-1  offset-md-9 offset-sm-8 p-md-1">
    <button   mat-raised-button>Update</button>
  </div>
</div-->
<!--div class="row p-md-3 page__box1">
      <div class="col-md-1">
      </div>
      <div class="col-md-3">
      </div>
      <div [formGroup]="currentNotePageFrom" class="col-md-5">
        <mat-form-field  class="w-100" [style.fontSize]="10"  appearance="outline">
          
          <mat-label>Page Title</mat-label>
          <textarea required formControlName="page_title" [(ngModel)]="currentNotePageObj.page_title" matInput
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="4"></textarea>
      </mat-form-field>
      </div>
      <div class="col-md-2 p-1 p-md-2">
        <button (click)="pageAddUpdate()" mat-raised-button>Add/Update</button>
      </div>
</div-->
 
<div class="container-fluid mt-1 mt-md-2 ">
      <div class="stickyPanelLeft">
          
          <div [@inOutAnimation]="hide ? 'open' : 'closed'" class="c2wow-pages-list p-0" *ngIf="true">
            
            <ul  class="mt-2 list-group" style="width:24px">
              <li  class="list-group-item list-group-item-secondary" >
                <mat-icon color="accent" title="AI Tool" class="c2wow-pointer  d-block d-lg-none" (click)="triggerRightPnl()" matSuffix>search</mat-icon>
                <mat-icon color="primary" class="c2wow-pointer" (click)="previewNote()" matSuffix>{{previewContent?'edit_square':'text_snippet'}}</mat-icon>

                <mat-icon color="primary" class="c2wow-pointer" (click)="addNewPage()" matSuffix>add_box</mat-icon>
                <mat-icon color="primary" class="c2wow-pointer" (click)="deleteNotePage()"   matPrefix>delete</mat-icon>
                <mat-icon color="primary" class="c2wow-pointer" (click)="triggerAnimation()" *ngIf="hide" matPrefix>arrow_back_ios</mat-icon>
                <mat-icon color="primary" class="c2wow-pointer" (click)="triggerAnimation()" *ngIf="!hide" matPrefix>arrow_forward_ios</mat-icon>
                 

              </li>
            </ul>
          
            <ul  class="mt-2 list-group"  style="max-height:300px;overflow-y: scroll;overflow-x:hidden">              
              <li *ngFor="let c2wowPg of note_pages; let pgIndx = index" 
              class="list-group-item list-group-item-primary d-flex justify-content-between" 
              style="min-width:200px"
              [ngClass]="{'active':c2wowPg.id == page_id}"
              (click)="selectNotePage(c2wowPg.id)">
                <span class="float-left" *ngIf="!hide">{{pgIndx+1}}</span>
                <span class="py-md-3 float-left" *ngIf="hide">{{pgIndx+1}} {{c2wowPg.page_title.trim()!=''?c2wowPg.page_title.trim():'Page '}}   
                </span>
                <button *ngIf="hide" class="float-right" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                  <mat-icon (click)="deleteNotePage(c2wowPg.id,$event)">delete</mat-icon>
                </button>

              </li>
            
              
            </ul>
          </div>


      </div>  

      <div class="stickyPanelRightButton d-none d-lg-none">
        <button  (click)="triggerRightPnl()" mat-icon-button color="primary" aria-label="Send" matSuffix>
          <mat-icon  matPrefix>bolt</mat-icon>
        </button>
      </div>
      <div class="stickyPanelRight d-block d-lg-none">

        <div [@inOutAnimationRightPanel]="hideRightPnl ? 'c2wowOpenRightPnl' : 'c2wowCloseRightPnl'" class="c2wow-pages-list p-0" *ngIf="true">
          
          <mat-card  class="ai-card">
            <mat-card-content>
              <div class="row c2wow-primary-color px-3">
                <div class="col-4 pt-2">
                  <mat-icon matPrefix>bolt</mat-icon>
                </div>
                <div class="col-6 pt-2">
                  AI (Use Wisely)
                </div>
                <div class="col-1">
                  <button  (click)="triggerRightPnl()" mat-icon-button color="primary" aria-label="Send" matSuffix>
                    <mat-icon  matPrefix>disabled_by_default</mat-icon>
                  </button>
                </div>
              </div>
              <mat-form-field class="pt-2" style="width:100%" [style.fontSize]="22" appearance="fill">
                <!--input matInput type="search" placeholder="search here" class="search-input"-->
                <mat-label>Topic Search</mat-label>
                <textarea matInput [(ngModel)]="topicSearcReq"
                      cdkTextareaAutosize
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="4"></textarea>
                <button  (click)="aiHelpSearch()" mat-icon-button color="primary" aria-label="Send" matSuffix>
                  <mat-icon matPrefix>search</mat-icon>
                </button>
              </mat-form-field>
              <mat-divider></mat-divider>
              <div>
                <button  [cdkCopyToClipboard]="topicSearcRes" class="right" mat-icon-button color="primary" aria-label="Copy" matSuffix>
                  <mat-icon>content_copy</mat-icon>
                </button>
              </div>
            
              <textarea class="search-res-content" id="clipboard-example-textarea"   [(ngModel)]="topicSearcRes"></textarea>
              <mat-divider></mat-divider>
            </mat-card-content>
          </mat-card>

        </div>
      </div>  


  <div class="row">

    
    <div class="pr-0 col-12 col-lg-9">
      <div class="row">
        <div class="col-1 d-none d-md-block"></div>
        <div class="col-8 col-md-5 p-2" [formGroup]="currentNotePageFrom" >
          <input required formControlName="page_title" [(ngModel)]="currentNotePageObj.page_title" class="form-control" type="text" placeholder="Page Title">
        </div>
        <div class="col-4 col-md-2 p-2 p-md-2">
          <button (click)="pageAddUpdate()" mat-raised-button>Update</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 p-0">
          <div *ngIf="!previewContent" [ngxSummernote]="smnConfig"  [(ngModel)]="currentNotePageObj.page_content"></div> 
          <app-preview-doc *ngIf="previewContent" [htmlContent]="currentNotePageObj.page_content"></app-preview-doc>
         
        </div>
      </div>
         
    </div>

    <div class="pl-4 ml-0 col-12 col-lg-3 d-none d-lg-block">
    
      <mat-card class="ai-card">
        <mat-card-content>
          <div class="row c2wow-primary-color">
            <div class="col-4 pt-2">
              <mat-icon color="primary" matPrefix>bolt</mat-icon>
            </div>
            <div class="col-7 pt-2">
              AI (Use Wisely)
            </div>
            <div class="col-1"></div>
          </div>
          <mat-form-field class="pt-2" style="width:100%" [style.fontSize]="22" appearance="fill">
            <!--input matInput type="search" placeholder="search here" class="search-input"-->
            <mat-label>Topic Search</mat-label>
            <textarea matInput [(ngModel)]="topicSearcReq"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="4"></textarea>
            <button  (click)="aiHelpSearch()" mat-icon-button color="primary" aria-label="Send" matSuffix>
              <mat-icon matPrefix>search</mat-icon>
            </button>
          </mat-form-field>
          <mat-divider></mat-divider>
          <div>
            <button  [cdkCopyToClipboard]="topicSearcRes" class="right" mat-icon-button color="primary" aria-label="Copy" matSuffix>
              <mat-icon>content_copy</mat-icon>
            </button>
          </div>
        
          <textarea class="search-res-content" id="clipboard-example-textarea"   [(ngModel)]="topicSearcRes"></textarea>
          <mat-divider></mat-divider>
        </mat-card-content>
      </mat-card>

    </div>
    
  </div>
</div>



</div>