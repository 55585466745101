 <!--  footer -->
 <footer>
    <div class="footer">
       <div class="container">
          <div class="row">
             <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="infoma text_align_left">
                   <h3>Choose.</h3>
                   <ul class="commodo">
                      <li>Commodo</li>
                      <li>consequat. Duis a</li>
                      <li>ute irure dolor</li>
                      <li>in reprehenderit </li>
                      <li>in voluptate </li>
                   </ul>
                </div>
             </div> -->
             
             <div class="col-lg-4 col-md-6 col-sm-6">
               <div class="infoma">
                  <h3>Company.</h3>
                  <ul class="menu_footer">
                     <li><a routerLink="/home">Home</a></li>
                     <li><a  routerLink="/insights">Insights</a></li>
                     <li><a routerLink="/contact-us">Contact Us</a></li>
                     <li><a routerLink="/terms">Terms</a></li>
                     <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                  </ul>
               </div>
            </div>
             <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="infoma text_align_left">
                   <h3>Services.</h3>
                   <ul class="menu_footer">
                     <li><a routerLink="/auth">NotesWiz </a></li>
                   </ul>
                </div>
             </div>
             <div class="col-lg-4 col-md-6 col-sm-6">
               <div class="infoma">
                  <h3>Get Support.</h3>
                  <ul class="conta">
                     <!-- <li><i class="fa fa-map-marker" aria-hidden="true"></i>Address : Loram Ipusm 
                     </li>
                     <li><i class="fa fa-phone" aria-hidden="true"></i>Call : +01 1234567890</li> -->
                     <li> <i class="fa fa-envelope" aria-hidden="true"></i>
                        <a  href="mailto:contact-us@c2wow.com">Email : contact-us@c2wow.com</a>
                     </li>
                  </ul>
               </div>
            </div>
          </div>
       </div>
       <div class="copyright">
          <div class="container">
             <div class="row">
                <div class="col-md-12">
                  <p>Your use of this site is subject to our <a routerLink="/terms">Terms of Use</a>,Cookies and <a routerLink="/privacy-policy">Privacy Policy</a>. We provide information and software and you are responsible for appropriately using this material. </p>
                  <p>© 2023 {{env.copyRight}}. All Rights Reserved.</p>
                </div>
             </div>
          </div>
       </div>
    </div>
 </footer>
 <!-- end footer -->