import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-without-login',
  templateUrl: './without-login.component.html',
  styleUrls: ['./without-login.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WithoutLoginComponent {

}
