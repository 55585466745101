    <!-- contact -->
    <div class="contact">
        <div class="container">
           <div class="row ">
              <div class="col-md-12">
                 <div class="titlepage text_align_center">
                    <h2>Contact <span class="blue_light">Us</span></h2>
                 </div>
              </div>
           
              <div class="col-md-10 offset-md-1">
                 <form class="main_form" #contactform [ngClass]="{'was-validated':submit_try,'needs-validation':!submit_try}" novalidate>
                    <div class="row">
                       <div class="col-md-12 ">
                          <input class="form-control contactus" placeholder="Name" type="type" [(ngModel)]="formData.name" name="name" required> 
                          <div class="invalid-feedback">
                           Please provide valid data.
                         </div>
                       </div>
                       <div class="col-md-12">
                          <input class="form-control contactus" placeholder="Phone number" type="type" [(ngModel)]="formData.phone" name="phone" > 
                          <div class="invalid-feedback">
                           Please provide valid data.
                         </div>                         
                       </div>
                       <div class="col-md-12">
                          <input class="form-control contactus" placeholder="Your Email" type="email" [(ngModel)]="formData.email" name="email" required> 
                          <div class="invalid-feedback">
                           Please provide valid data.
                         </div>                         
                       </div>
                       <div class="col-md-12">
                          <textarea class="form-control textarea" placeholder="Message" type="text" [(ngModel)]="formData.message" name="message" required></textarea>
                          <div class="invalid-feedback">
                           Please provide valid data.
                         </div>
                       </div>
                       <div class="col-md-12">
                          <button class="send_btn" (click)="submitForm()">Submit Now</button>
                       </div>
                    </div>
                 </form>
              </div>
           </div>
        </div>
     </div>
     <!-- contact -->

     