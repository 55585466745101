import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nl2br'
})
export class Nl2brPipe implements PipeTransform {
  transform(inpString: string): string {
    let retRes = '';
    try{
      if(inpString){
        retRes = inpString.replace(/\n\n/g, '<br/>');
      }
    }catch(err){
      console.log('Err Pipe Nl2brPipe :-',err)
    }
    return retRes;
  }
}

/*
sanitizer.bypassSecurityTrustHtml(

  import { DomSanitizer } from '@angular/platform-browser';


  */