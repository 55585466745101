import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { JwtService } from './jwt.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router,private apiService:ApiService, private jwtService:JwtService) { }

  loginWithGoogle(params: any) {
    //let params:any = {'CredentialId':CredentialId};
    this.apiService.post('/auth/login',params).subscribe(res => {
      if(res.status){
        this.router.navigate(['/notes/list'])
        this.jwtService.setSession(res.data);
      }
    });
  }
}  
