import { Component, EventEmitter, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { AiService } from '../services/ai.service';
import { SearchService } from '../services/search.service';
import { AuthService } from '../services/auth.service';    
import { JwtService } from '../services/jwt.service';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { environment } from 'src/environments/environment';
 
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  searchText:string ='';
  userDetails:any={};
  //aiService:AiService;
  @Output() toggleSidebarForMe: EventEmitter<any> = new EventEmitter();

  public loggedInStatus:any = false;

  constructor(private router: Router, public aiService:AiService
    , private searchService:SearchService, private authService:AuthService
    , public jwtService:JwtService, private ref: ChangeDetectorRef,private ngxUiLoaderService:NgxUiLoaderService) {

      //this.ngxUiLoaderService.start('mainSearchLoader');
      jwtService.eventEmitterUser.subscribe(resData => {
        this.userDetails = resData.data
        if( this.userDetails.id>0){
          this.loggedInStatus = true
        }else{
          this.loggedInStatus = false
        }
        ref.detectChanges()
      })
  }

  ngOnInit() {
    this.jwtService.getUserDetail()
    this.searchQuotes()
    return;
    // @ts-ignore
    if(google){
      // @ts-ignore
      google.accounts.id.initialize({
        client_id: "1084841814350-ivrr1ha9jvf727s32tsu5bbbup51q8kj.apps.googleusercontent.com",
        callback: this.handleCredentialResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true,
        cookiepolicy: 'single_host_origin',
    
      });
      // @ts-ignore
      google.accounts.id.renderButton(
      // @ts-ignore
      document.getElementById("google-button"),
        { theme: "outline", size: "large", width: "100px" }
      );
      // @ts-ignore
      google.accounts.id.prompt((notification: PromptMomentNotification) => {});
    }
    
  }

  ngAfterViewInit(){
    this.googleBtnIni();
  }
   
    
  googleBtnIni(){
    // @ts-ignore
    //if(google){
     // @ts-ignore
      google.accounts.id.initialize({
        client_id: environment.google.client_id,
        callback: this.handleCredentialResponse.bind(this),
        auto_select: false,
        cancel_on_tap_outside: true,
        //cookiepolicy: 'single_host_origin',
    
      });
     
        // @ts-ignore
      google.accounts.id.renderButton(
      // @ts-ignore
          document.getElementById("google-button"),
            { theme: "outline",text:"signin", size: "medium", width: "20%" }
        );
        
      // @ts-ignore
      /*
      google.accounts.id.prompt((notification: PromptMomentNotification) => {

        console.log(notification)
      });
      */
    //}
  }

  async handleCredentialResponse(response: any) {
    // Here will be your response from Google.
    // console.log(response);
    if(response.credential){
      this.authService.loginWithGoogle(response);
    }
  }

  toggleSidebar() {
    this.toggleSidebarForMe.emit({'toggleSidebar':true});
  }
  openSidebar(){
    this.toggleSidebarForMe.emit({'openSidebar':true});
  }
  closeSidebar(){
  
    this.toggleSidebarForMe.emit({'closeSidebar':true});
  }
  searchInit(){
    this.closeSidebar()
    this.ngxUiLoaderService.start('mainSearchLoader');
    this.searchService.mainSerach(this.searchText);

    //this.searchService.imgWork(this.searchText);
    this.searchQuotes()
  }

  searchQuotes(){
    let searchTxt = this.searchText.trim()
    if(searchTxt.length==0){
      searchTxt = 'default'
    }
    this.ngxUiLoaderService.start('searchQuoteLoader');
    this.searchService.serachQuotes(searchTxt);
  }

  logOut(){
    this.closeSidebar()
    this.jwtService.logout();
    this.googleBtnIni();
    this.router.navigate(['/'])
  }
}
