import { Component, ViewChild, AfterViewInit } from '@angular/core';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort, Sort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AddEditNoteComponent } from '../shared/dialogs/add-edit-note/add-edit-note.component'
import {MatTableDataSource} from '@angular/material/table'
import { SearchService } from '../services/search.service';
import { GlobalService } from '../services/global.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  aiResponse: any;
  aiResponseImg: any = './assets/img/avtr_ai.jpg';
  searchQuotes:any[] = [];
  displayedColumns: string[] = ['select','position', 'topic', 'pages','createdOn','publishedOn','status','actions'];
  // dataSource = ELEMENT_DATA;
  
//"a white siamese cat",
  
  constructor(public dialog: MatDialog, private _liveAnnouncer: LiveAnnouncer
    , private searchService:SearchService, private globalService:GlobalService ){

      searchService.eventEmitterHeaderSearch.subscribe(resService => {
       // debugger
      if(resService.resType =='createCompletion' && resService.data){
        this.aiResponse = resService.data;
      } else if(resService.resType =='aiImage' && resService.data){
        this.aiResponseImg = resService.data
      } else if(resService.resType =='searchQuotesHome' && resService.data){
        this.searchQuotes = resService.data
      }else{

      }
      
    })
  }

  ngAfterViewInit() {
    this.loadScriptsSearchBox();
    this.loadInitData()
  };

  loadScriptsSearchBox(): any {
  }

  shareContent(inpText:any){
    navigator.share({
      title: 'Notes',
      text: inpText,
      url:'http://localhost'
    })
  }

  loadInitData(){
    this.globalService.showHideLoader('searchQuoteLoader')
    this.searchService.serachQuotes('default');
  }
}
