import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError} from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { HttpHeaders, HttpClient, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { JwtService } from './jwt.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private router: Router
    ) { }
    private setApiHeaders(): HttpHeaders {
      let apiHeaders = {
        'Accept':'application/json',
        'Content-Type':'application/json',
        observe: 'response',
        'Authorization':''
      }
      if(this.jwtService.getToken()){
        apiHeaders['Authorization'] = this.jwtService.getToken();
      }
      return new HttpHeaders(apiHeaders);

    }

    private setDocHeaders(): HttpHeaders {
      let apiHeaders = {
        'Accept':'application/json',
        'Content-Type':'application/json',
        observe: 'response',
        'Authorization':''
      }

      if(this.jwtService.getToken()){
        apiHeaders['Authorization'] = this.jwtService.getToken();
      }
      return new HttpHeaders(apiHeaders);

    }

    
    private checkTokenStatus(inpRes:any){
      if(inpRes.logOut){
        this.jwtService.logout()
        this.router.navigate(['/home'])
      }
      return inpRes
    }
    private errorFormatter(err: any){
      console.log('New Err',err)
      return new BehaviorSubject(err);
    }
    

    get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
      return this.http.get(environment.api_base+path, {headers: this.setApiHeaders(), params: params})
      .pipe(map(this.checkTokenStatus.bind(this)),catchError((err) => {
        return throwError(() => err);
      }))
    }

    put(path: string, body: Object = {}, params: HttpParams = new HttpParams()): Observable<any> {
      return this.http.put(environment.api_base+path,JSON.stringify(body), {headers: this.setApiHeaders(), params: params})
      .pipe(map(this.checkTokenStatus.bind(this)),catchError(this.errorFormatter))
    }

    post(path: string, body: Object = {}, params: HttpParams = new HttpParams()): Observable<any> {
      return this.http.post(environment.api_base+path,body, {headers: this.setApiHeaders(), params: params})
      .pipe(map(this.checkTokenStatus.bind(this)),catchError(this.errorFormatter))
    }

    delete(path: string, body: Object = {}, params: HttpParams = new HttpParams()): Observable<any> {
      return this.http.delete(environment.api_base+path, {headers: this.setApiHeaders(), body: JSON.stringify(body)})
      .pipe(map(this.checkTokenStatus.bind(this)),catchError(this.errorFormatter))
    }

    getDoc(path: string, params: HttpParams = new HttpParams()): Observable<any> {
      return this.http.get(environment.api_base+path, {responseType: 'blob', headers: this.setDocHeaders(), params: params})
      .pipe(map(this.checkTokenStatus.bind(this)),catchError(this.errorFormatter))
    }

}
