import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotesModule } from './notes/notes.module';
import { TempComponent } from './temp/temp.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { LoginResolver } from './services/resolvers/login-resolver';

import { WithoutLoginHomeComponent } from './without-login-home/without-login-home.component';
import { WithoutLoginHeaderComponent } from './without-login-header/without-login-header.component';
import { WithoutLoginComponent } from './layouts/without-login/without-login.component';
import { WithLoginComponent } from './layouts/with-login/with-login.component';

import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { InsightsComponent } from './insights/insights.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermConditionsComponent } from './term-conditions/term-conditions.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full' 
  },
  {
    path:'',
    component: WithoutLoginComponent,
    children: [
      {
        path: 'auth',
        component: AuthenticationComponent,
    },
    {
      path: 'home',
      component: WithoutLoginHomeComponent,
    },
    {
      path: 'about-us',
      component: AboutUsComponent,
    },
    {
      path: 'contact-us',
      component: ContactUsComponent,
    },
    {
      path: 'insights',
      component: InsightsComponent,
    },
    {
      path: 'privacy-policy',
      component: PrivacyPolicyComponent,
    },
    {
      path: 'terms',
      component: TermConditionsComponent,
    },
    ]
  },
  {
    path: 'notes',
    component: WithLoginComponent,
    loadChildren: () => NotesModule
  },
  {
    path: 'temp',
    component: TempComponent
  },
  { path: '**', redirectTo: '/home' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: [
  ]
})
export class AppRoutingModule { }
 